exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-a-beginner-s-guide-on-creating-a-portfolio-website-with-github-and-gatsby-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/a-beginner-s-guide-on-creating-a-portfolio-website-with-github-and-gatsby/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-a-beginner-s-guide-on-creating-a-portfolio-website-with-github-and-gatsby-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-challenges-in-headless-wordpress-with-gatsbyjs-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/challenges-in-headless-wordpress-with-gatsbyjs/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-challenges-in-headless-wordpress-with-gatsbyjs-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-creating-your-blog-using-gatsbyjs-and-dev-to-api-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/creating-your-blog-using-gatsbyjs-and-dev-to-api/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-creating-your-blog-using-gatsbyjs-and-dev-to-api-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-getting-started-with-gatsbyjs-and-wordpress-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/getting-started-with-gatsbyjs-and-wordpress/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-getting-started-with-gatsbyjs-and-wordpress-index-mdx" */)
}

